import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	openMacroBrowserForInitialConfiguration,
	openMacroBrowserToEditExistingMacro,
} from '@confluence-classic/confluence-fabric-editor/src/lib/editor-extensions';
import { getExtensionHandlers } from '@confluence-classic/confluence-fabric-editor/src/lib/renderer-extensions';
import { populateExtensionProviders } from '@confluence-classic/confluence-fabric-editor/src/components/Renderer/populateExtensionProviders';
import { loadMacroBrowserResources } from '@confluence-classic/confluence-fabric-editor/src/lib/extensions-common/macro-loaders';
import { getMigrationExtensionManifest } from '@confluence-classic/confluence-fabric-editor/src/lib/extensions-common/migration-extension/manifest';

import { ClassicEditorContext } from '@confluence/editor-features/entry-points/ClassicEditorContext';

import Changeboarding from '../Changeboarding/Changeboarding';

const extensionUtils = {
	openMacroBrowserForInitialConfiguration,
	openMacroBrowserToEditExistingMacro,
	loadMacroBrowserResources,
	getMigrationExtensionManifest,
	getExtensionHandlers,
	populateExtensionProviders,
	Changeboarding,
};

/**
 * A component to inject extension v1 related functions into the editor extension context.
 * WARN: this component must not be used directly but only via Loadable.
 */
const ClassicEditorContextProvider = ({ children, isWithinEditor = false }) => {
	const ClassicEditorContextValue = useMemo(
		() => ({
			...extensionUtils,
			isWithinEditor,
		}),
		[isWithinEditor],
	);

	return (
		<ClassicEditorContext.Provider value={ClassicEditorContextValue}>
			{children}
		</ClassicEditorContext.Provider>
	);
};

ClassicEditorContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
	isWithinEditor: PropTypes.bool,
};

export default ClassicEditorContextProvider;
