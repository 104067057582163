import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { haveDiscoveredFeaturesBeenLoaded } from '@confluence-classic/confluence-legacy-redux';

import { showChangeboardingSpotlight } from './actions';
import {
	hasSpotlightBeenInvoked,
	isChangeboardingUrlBlacklisted,
	shouldChangeboardingInstanceRender,
} from './selectors';
import ChangeboardingComponent from './ChangeboardingComponent';

const mapStateToProps = (state, ownProps) => ({
	discoveredFeaturesLoaded: haveDiscoveredFeaturesBeenLoaded(state),
	shouldRender:
		shouldChangeboardingInstanceRender(state, ownProps) && !isChangeboardingUrlBlacklisted(state),
	spotlightHasBeenInvoked: hasSpotlightBeenInvoked(state.views.changeboarding, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
	showChangeboardingSpotlight({
		delaySpotlight = 0,
		spotlightId,
		changeboardFlowFinished,
		spotlightAttributes,
	}) {
		setTimeout(() => {
			dispatch(
				showChangeboardingSpotlight({
					spotlightId,
					changeboardFlowFinished,
					spotlightAttributes,
				}),
			);
		}, delaySpotlight);
	},
});
const Changeboarding = connect(mapStateToProps, mapDispatchToProps)(ChangeboardingComponent);

Changeboarding.propTypes = {
	spotlightId: PropTypes.string,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	darkFeatureEnabled: PropTypes.string,
	darkFeatureDisabled: PropTypes.string,
	delaySpotlight: PropTypes.number,
	spotlightAttributes: PropTypes.shape({
		heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
		footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
		targetBgColor: PropTypes.string,
		targetRadius: PropTypes.number,
		dialogPlacement: PropTypes.oneOf([
			'top left',
			'top center',
			'top right',
			'right top',
			'right middle',
			'right bottom',
			'bottom left',
			'bottom center',
			'bottom right',
			'left top',
			'left middle',
			'left bottom',
		]),
		dialogWidth: PropTypes.number,
	}),
};

Changeboarding.displayName = 'Changeboarding';

export default Changeboarding;
