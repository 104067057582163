import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ChangeboardingTargetLazyLoader } from './ChangeboardingLazyLoaders';
import { SPOTLIGHT_CALLBACKS } from './changeboarding-spotlights';

export default class ChangeboardingComponent extends Component {
	static propTypes = {
		children: PropTypes.any,
		shouldRender: PropTypes.bool.isRequired,
		spotlightId: PropTypes.string.isRequired,
		showChangeboardingSpotlight: PropTypes.func.isRequired,
		discoveredFeaturesLoaded: PropTypes.bool.isRequired,
		spotlightHasBeenInvoked: PropTypes.bool.isRequired,
		delaySpotlight: PropTypes.number,
		spotlightAttributes: PropTypes.object,
		changeboardFlowFinished: PropTypes.func,
	};

	componentDidMount() {
		SPOTLIGHT_CALLBACKS[this.props.spotlightId] = this.props.changeboardFlowFinished;
	}

	render() {
		const {
			children,
			shouldRender,
			spotlightId,
			delaySpotlight,
			spotlightHasBeenInvoked,
			showChangeboardingSpotlight,
			spotlightAttributes,
		} = this.props;

		if (!shouldRender) {
			return children;
		}

		if (!spotlightHasBeenInvoked) {
			showChangeboardingSpotlight({
				spotlightId,
				delaySpotlight,
				spotlightAttributes,
			});
		}
		return (
			<ChangeboardingTargetLazyLoader
				spotlightId={spotlightId}
				delaySpotlight={delaySpotlight}
				spotlightAttributes={spotlightAttributes}
			>
				{children}
			</ChangeboardingTargetLazyLoader>
		);
	}
}
