import { commonActionCreator } from '@confluence-classic/confluence-legacy-redux';

export const showChangeboardingSpotlight = commonActionCreator('CHANGEBOARDING_INVOKE');

export const setChangeboardingTargetReady = commonActionCreator('CHANGEBOARDING_TARGET_READY');

export const dismissChangeboarding = commonActionCreator('CHANGEBOARDING_DISMISS');

export const getNextChangeboardingSpotlight = commonActionCreator('CHANGEBOARDING_NEXT');

export const getPrevChangeboardingSpotlight = commonActionCreator('CHANGEBOARDING_PREV');

export const setSpotlightAttributes = commonActionCreator(
	'CHANGEBOARDING_SET_SPOTLIGHT_ATTRIBUTES',
);
